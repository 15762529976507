<template>
  <div class="nk-block">
    <div class="card">
      <div class="card-aside-wrap">
        <div class="card-inner card-inner-md">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h4 class="nk-block-title letter-spacing-2 size11">
                PREFERENCES
              </h4>
            </div>
            <div class="nk-block-head-content align-self-start d-lg-none">
              <a
                @click.prevent="toggleUserAside"
                href="#"
                class="toggle btn btn-icon btn-trigger mt-n1"
                data-target="userAside"
                ><em class="icon ni ni-menu-alt-r"></em>
              </a>
            </div>
          </div>
          <router-view />
        </div>
        <!-- .card-inner -->
        <div
          id="userAside"
          class="
            card-aside card-aside-left
            user-aside
            toggle-slide toggle-slide-left toggle-break-lg
          "
          data-content="userAside"
          data-toggle-screen="lg"
          data-toggle-overlay="true"
        >
          <div class="card-inner-group" data-simplebar>
            <div class="card-inner">
              <div class="user-card">
                <img
                  :src="User.photo_url"
                  :alt="User.fullname"
                  style="width: 20%"
                  class="rounded-circle mr-3"
                />
                <div class="user-info">
                  <span class="lead-text">{{ User.fullname }} </span>
                  <span class="sub-text">{{ User.email }} </span>
                </div>
                <div class="user-action">
                  <div class="dropdown">
                    <a
                      class="btn btn-icon btn-trigger mr-n2"
                      data-toggle="dropdown"
                      href="#"
                      ><em class="icon ni ni-more-v"></em>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <ul class="link-list-opt no-bdr">
                        <li>
                          <a href="#"
                            ><em class="icon ni ni-camera-fill"></em>
                            <span>Change Photo</span></a
                          >
                        </li>
                        <li>
                          <a href="#"
                            ><em class="icon ni ni-edit-fill"></em>
                            <span>Update Profile</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="user-info mt-3">
                <div class="size10 letter-spacing-2 w3-text-grey">
                  DEFAULT COMPANY
                </div>
                <span class="lead-text">{{ userMerchant.company_name }} </span>
                <span class="sub-text">{{ userMerchant.merchant_id }} </span>
              </div>
            </div>
            <div class="card-inner p-0">
              <ul class="link-list-menu">
                <li v-for="menu in Menu" :key="menu.name">
                  <router-link @click="hideUserAside" :to="{ name: menu.name }"
                    ><em v-if="menu.icon" :class="[menu.icon]"></em>
                    <span>{{ menu.title }}</span></router-link
                  >
                </li>
              </ul>
            </div>
            <!-- .card-inner -->
          </div>
          <!-- .card-inner-group -->
        </div>
        <!-- .card-aside -->
      </div>
      <!-- .card-aside-wrap -->
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { Util } from "@/helpers/utilities";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const title = ref("Settings");
    const store = useStore();
    const User = computed(() => store.state.auth.userData);
    const userMerchant = computed(() => store.state.auth.userMerchant);
    const Menu = Util.menuChildren("settings");
    const toggleUserAside = () => {
      const aside = document.querySelector("#userAside");
      if (aside.classList.contains("toggle-slide-left"))
        aside.classList.remove("toggle-slide-left");
      else aside.classList.add("toggle-slide-left");
    };
    const hideUserAside = () => {
      const aside = document.querySelector("#userAside");
      if (!aside.classList.contains("toggle-slide-left"))
        aside.classList.add("toggle-slide-left");
    };
    return {
      title,
      User,
      userMerchant,
      Menu,
      toggleUserAside,
      hideUserAside,
    };
  },
};
</script>